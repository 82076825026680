export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const CREATE_PARTNER_LOADING = 'CREATE_PARTNER_LOADING';
export const CREATE_PARTNER_ERROR = 'CREATE_PARTNER_ERROR';
export const CREATE_PARTNER_RESET = 'CREATE_PARTNER_RESET';

export const GET_VEHICLE_TYPE_SUCCESS = 'GET_VEHICLE_TYPE_SUCCESS';
export const GET_VEHICLE_TYPE_LOADING = 'GET_VEHICLE_TYPE_LOADING'
export const GET_VEHICLE_TYPE_ERROR = 'GET_VEHICLE_TYPE_ERROR';
export const GET_VEHICLE_TYPE_RESET = 'GET_VEHICLE_TYPE_RESET';

export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS';
export const GET_CITY_LIST_LOADING = 'GET_CITY_LIST_LOADING';
export const GET_CITY_LIST_ERROR = 'GET_CITY_LIST_ERROR';
export const GET_CITY_LIST_RESET = 'GET_CITY_LIST_RESET';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_RESET = 'REGISTER_RESET';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_RESET = 'LOGIN_RESET';

export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_ERROR = 'CREATE_BOOKING_ERROR';
export const CREATE_BOOKING_LOADING = 'CREATE_BOOKING_LOADING';
export const CREATE_BOOKING_RESET = 'CREATE_BOOKING_RESET';

export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_ERROR = 'GET_BOOKING_ERROR';
export const GET_BOOKING_LOADING = 'GET_BOOKING_LOADING';
export const GET_BOOKING_RESET = 'GET_BOOKING_RESET';

export const GET_BOOKING_INFO_SUCCESS = 'GET_BOOKING_INFO_SUCCESS';
export const GET_BOOKING_INFO_ERROR = 'GET_BOOKING_INFO_ERROR';
export const GET_BOOKING_INFO_LOADING = 'GET_BOOKING_INFO_LOADING';
export const GET_BOOKING_INFO_RESET = 'GET_BOOKING_INFO_RESET';

export const ADMIN_CREATE_CITY_SUCCESS = 'ADMIN_CREATE_CITY_SUCCESS';
export const ADMIN_CREATE_CITY_ERROR = 'ADMIN_CREATE_CITY_ERROR';
export const ADMIN_CREATE_CITY_LOADING = 'ADMIN_CREATE_CITY_LOADING';
export const ADMIN_CREATE_CITY_RESET = 'ADMIN_CREATE_CITY_RESET';

export const ADMIN_UPDATE_CITY_SUCCESS = 'ADMIN_UPDATE_CITY_SUCCESS';
export const ADMIN_UPDATE_CITY_ERROR = 'ADMIN_UPDATE_CITY_ERROR';
export const ADMIN_UPDATE_CITY_LOADING = 'ADMIN_UPDATE_CITY_LOADING';
export const ADMIN_UPDATE_CITY_RESET = 'ADMIN_UPDATE_CITY_RESET';

export const VEHICLE_CREATE_SUCCESS = 'VEHICLE_CREATE_SUCCESS';
export const VEHICLE_CREATE_ERROR = 'VEHICLE_CREATE_ERROR';
export const VEHICLE_CREATE_LOADING = 'VEHICLE_CREATE_LOADING';
export const VEHICLE_CREATE_RESET = 'VEHICLE_CREATE_RESET';

export const VEHICLE_UPDATE_SUCCESS = 'VEHICLE_UPDATE_SUCCESS';
export const VEHICLE_UPDATE_ERROR = 'VEHICLE_UPDATE_ERROR';
export const VEHICLE_UPDATE_LOADING = 'VEHICLE_UPDATE_LOADING';
export const VEHICLE_UPDATE_RESET = 'VEHICLE_UPDATE_RESET';

export const PARTNERS_SUCCESS = 'PARTNERS_SUCCESS';
export const PARTNERS_ERROR = 'PARTNERS_ERROR';
export const PARTNERS_LOADING = 'PARTNERS_LOADING';
export const PARTNERS_RESET = 'PARTNERS_RESET';

export const PARTNER_SUCCESS = 'PARTNER_SUCCESS';
export const PARTNER_ERROR = 'PARTNER_ERROR';
export const PARTNER_LOADING = 'PARTNER_LOADING';
export const PARTNER_RESET = 'PARTNER_RESET';

export const BOOKINGS_SUCCESS = 'BOOKINGS_SUCCESS';
export const BOOKINGS_ERROR = 'BOOKINGS_ERROR';
export const BOOKINGS_LOADING = 'BOOKINGS_LOADING';
export const BOOKINGS_RESET = 'BOOKINGS_RESET';

export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
export const BOOKING_ERROR = 'BOOKING_ERROR';
export const BOOKING_LOADING = 'BOOKING_LOADING';
export const BOOKING_RESET = 'BOOKING_RESET';

export const PAYMENT_CONFIRM_SUCCESS = 'PAYMENT_CONFIRM_SUCCESS';
export const PAYMENT_CONFIRM_ERROR = 'PAYMENT_CONFIRM_ERROR';
export const PAYMENT_CONFIRM_LOADING = 'PAYMENT_CONFIRM_LOADING';
export const PAYMENT_CONFIRM_RESET = 'PAYMENT_CONFIRM_RESET';

export const USER_BOOKINGS_SUCCESS = 'USER_BOOKINGS_SUCCESS';
export const USER_BOOKINGS_ERROR = 'USER_BOOKINGS_ERROR';
export const USER_BOOKINGS_LOADING = 'USER_BOOKINGS_LOADING';
export const USER_BOOKINGS_RESET = 'USER_BOOKINGS_RESET';

export const USER_CONTACT_SUCCESS = 'USER_CONTACT_SUCCESS';
export const USER_CONTACT_ERROR = 'USER_CONTACT_ERROR';
export const USER_CONTACT_LOADING = 'USER_CONTACT_LOADING';
export const USER_CONTACT_RESET = 'USER_CONTACT_RESET';

export const OWNER_VEHICLES_SUCCESS = 'OWNER_VEHICLES_SUCCESS';
export const OWNER_VEHICLES_ERROR = 'OWNER_VEHICLES_ERROR';
export const OWNER_VEHICLES_LOADING = 'OWNER_VEHICLES_LOADING';
export const OWNER_VEHICLES_RESET = 'OWNER_VEHICLES_RESET';

export const OWNER_DRIVERS_SUCCESS = 'OWNER_DRIVERS_SUCCESS';
export const OWNER_DRIVERS_ERROR = 'OWNER_DRIVERS_ERROR';
export const OWNER_DRIVERS_LOADING = 'OWNER_DRIVERS_LOADING';
export const OWNER_DRIVERS_RESET = 'OWNER_DRIVERS_RESET';

export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_ERROR = 'GET_CONTACT_ERROR';
export const GET_CONTACT_LOADING = 'GET_CONTACT_LOADING';
export const GET_CONTACT_RESET = 'GET_CONTACT_RESET';

export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR';
export const GET_CONTACTS_LOADING = 'GET_CONTACTS_LOADING';
export const GET_CONTACTS_RESET = 'GET_CONTACTS_RESET';

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const GET_USERS_RESET = 'GET_USERS_RESET';

export const GET_DRIVERS_SUCCESS = 'GET_DRIVERS_SUCCESS';
export const GET_DRIVERS_ERROR = 'GET_DRIVERS_ERROR';
export const GET_DRIVERS_LOADING = 'GET_DRIVERS_LOADING';
export const GET_DRIVERS_RESET = 'GET_DRIVERS_RESET';

export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';
export const GET_VEHICLES_ERROR = 'GET_VEHICLES_ERROR';
export const GET_VEHICLES_LOADING = 'GET_VEHICLES_LOADING';
export const GET_VEHICLES_RESET = 'GET_VEHICLES_RESET';

export const GET_OWNERS_SUCCESS = 'GET_OWNERS_SUCCESS';
export const GET_OWNERS_ERROR = 'GET_OWNERS_ERROR';
export const GET_OWNERS_LOADING = 'GET_OWNERS_LOADING';
export const GET_OWNERS_RESET = 'GET_OWNERS_RESET';

export const APPROVE_PARTNER_SUCCESS = 'APPROVE_PARTNER_SUCCESS';
export const APPROVE_PARTNER_ERROR = 'APPROVE_PARTNER_ERROR';
export const APPROVE_PARTNER_LOADING = 'APPROVE_PARTNER_LOADING';
export const APPROVE_PARTNER_RESET = 'APPROVE_PARTNER_RESET';

export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_ERROR = 'GET_DASHBOARD_ERROR';
export const GET_DASHBOARD_LOADING = 'GET_DASHBOARD_LOADING';
export const GET_DASHBOARD_RESET = 'GET_DASHBOARD_RESET';

export const CREATE_DRIVER_SUCCESS = 'CREATE_DRIVER_SUCCESS';
export const CREATE_DRIVER_ERROR = 'CREATE_DRIVER_ERROR';
export const CREATE_DRIVER_LOADING = 'CREATE_DRIVER_LOADING';
export const CREATE_DRIVER_RESET = 'CREATE_DRIVER_RESET';

export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_ERROR = 'CREATE_VEHICLE_ERROR';
export const CREATE_VEHICLE_LOADING = 'CREATE_VEHICLE_LOADING';
export const CREATE_VEHICLE_RESET = 'CREATE_VEHICLE_RESET';