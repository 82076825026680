import { ADMIN_CREATE_CITY_SUCCESS, ADMIN_CREATE_CITY_ERROR, ADMIN_CREATE_CITY_LOADING, ADMIN_CREATE_CITY_RESET } from '../types';
export const defaultState = {
  payload: null,
  loading: false,
  error: false,
  success: false
};

const index = (state = defaultState, action) => {
  switch (action.type) {
    case ADMIN_CREATE_CITY_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        payload: action.payload
      };
    case ADMIN_CREATE_CITY_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        payload: action.payload
      };
    case ADMIN_CREATE_CITY_LOADING:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
        payload: null
      };
    case ADMIN_CREATE_CITY_RESET:
      return {
        ...state,
        success: false,
        error: false,
        loading: false,
        payload: null
      };
    default:
      return state;
  }
}

export default index;