import { combineReducers } from "redux";
import createPartnerReducer from "./createPartnerReducer";
import getVehicleTypesReducer from "./getVehicleTypesReducer";
import getCityListReducer from "./getCityListReducer";
import registerReducer from "./registerReducer";
import loginReducer from "./loginReducer";
import createBookingReducer from './createBookingReducer';
import getBookingInfoReducer from './getBookingInfoRedicer';
import createCityReducer from './createCityReducer';
import updateCityReducer from './updateCityReducer';
import createVehicleReducer from './createVehicleTypeReducer';
import updateVehicleReducer from './updateVehicleReducer';
import partnersReducer from './partnersReducer';
import partnerReducer from './partnerReducer';
import bookingsReducer from './bookingsReducer';
import bookingReducer from './bookingReducer';
import userBookingsReducer from './userBookingsReducer';
import postContact from './postContactReducer';
import ownerVehicles from './ownerVehiclesReducer';
import ownerDrivers from './ownerDriversReducer';
import contacts from './getContactsReducer';
import users from './getUsersReducer';
import drivers from './getDriversReducer';
import vehicles from './getVehiclesReducer';
import owners from './getOwnersReducer';
import approvePartner from './approveRequestReducer';
import dashboard from './getDashboardReducer';
import createDriver from './createDriverReducer';
import createVehicle from "./createVehicleReducer";

const reducers = combineReducers({
  createPartner: createPartnerReducer,
  vehicleTypes: getVehicleTypesReducer,
  cityList: getCityListReducer,
  register: registerReducer,
  login: loginReducer,
  createBooking: createBookingReducer,
  bookingInfo: getBookingInfoReducer,
  createCity: createCityReducer,
  updateCity: updateCityReducer,
  createVehicleType: createVehicleReducer,
  updateVehicleType: updateVehicleReducer,
  partners: partnersReducer,
  partner: partnerReducer,
  bookings: bookingsReducer,
  booking: bookingReducer,
  userBookings: userBookingsReducer,
  postContact: postContact,
  ownerVehicles: ownerVehicles,
  ownerDrivers: ownerDrivers,
  contacts: contacts,
  users: users,
  drivers: drivers,
  vehicles: vehicles,
  owners: owners,
  approvePartner: approvePartner,
  dashboard: dashboard,
  createDriver: createDriver,
  createVehicle: createVehicle,
})

export default reducers;