import { GET_DRIVERS_SUCCESS, GET_DRIVERS_ERROR, GET_DRIVERS_LOADING, GET_DRIVERS_RESET } from '../types';
export const defaultState = {
  payload: null,
  loading: false,
  error: false,
  success: false
};

const index = (state = defaultState, action) => {
  switch (action.type) {
    case GET_DRIVERS_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        payload: action.payload
      };
    case GET_DRIVERS_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        payload: action.payload
      };
    case GET_DRIVERS_LOADING:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
        payload: null
      };
    case GET_DRIVERS_RESET:
      return {
        ...state,
        success: false,
        error: false,
        loading: false,
        payload: null
      };
    default:
      return state;
  }
}

export default index;